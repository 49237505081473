import React, { useEffect, useLayoutEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { analyticClickEvent } from "../../../components/googleAnalytics"
import { ReactAudioPlayer, ReactVideoPlayer } from "../../../components/reactPlayer"
import LayoutNoStripe from "../../../components/layoutNoStripe"
import {
  Section,
  SectionHeading,
} from "../../../styledComponents/section"

import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo,
} from "../../../styledComponents/videoTestimonial"
import { StyledButton } from "../../../styledComponents/button"
import { EventListSection } from "../../../styledComponents/eventList"
import ProductList from "../../../components/ProductList"
import { StyledLinkButton } from "../../../styledComponents/button"
import Icon from "../../../components/icon"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const FhtjTotalRevitalizationChambersRecordingsVIP = ({ data, location }) => {

  let products = data.allWpProduct.edges

  const [tabIndex, setTabIndex] = useState(0);

  const checkIfHasQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has("session-1")) {
      setTabIndex(0)
    }
    if (urlParams.has("session-2")) {
      setTabIndex(1)
    }
  }

  useEffect(() => {
    checkIfHasQuery()
  }, [])

  const [isWatchFullVersionOpen, setIsWatchFullVersionOpen] = useState(false);
  const [isWatchFullVersionOpenMeditations, setIsWatchFullVersionOpenMeditations] = useState(false);
  const [isWatchFullVersionOpenMeditations1, setIsWatchFullVersionOpenMeditations1] = useState(false);
  const [isAutopPlay, setAutoPlay] = useState(false);

  const toggleTestimonials = (value) => {
    setIsWatchFullVersionOpen(value)
  }

  const toggleTestimonialsMeditation = (value) => {
    setIsWatchFullVersionOpenMeditations(value)
  }

  const toggleTestimonialsMeditation1 = (value) => {
    setIsWatchFullVersionOpenMeditations1(value)
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const autoplay = urlParams.get('autoplay')
      setAutoPlay(autoplay)
      return
    }
  }, [])

  return (
    <> <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />x
    </Helmet>
      <LayoutNoStripe displayNavBorder location={location} hideNewsletterSignUp>
        <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
          <StyledVideoTestimonialHeader>
            <h1 itemProp="headline">Total Revitalization Chambers VIP</h1>
          </StyledVideoTestimonialHeader>
          <EventListSection style={{ "padding": "0" }}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} >
              <TabList>
                <Tab>Call 1</Tab>
                <Tab>Call 2</Tab>
              </TabList>
              <TabPanel>
                {/* Session 1 */}
                <h2 style={{ 'text-align': 'center' }}>Radiant Essence Renewal - Call 1</h2>
                <p style={{ 'text-align': 'center' }}>Session 1 on Mar 14, 2025</p>
                <StyledVideoTestimonialVideo className="replay">
                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/TotalRevitalizationChambers-Zoom-Session-1-vip.mp4"
                    title="Total Revitalization Chambers Session VIP 1"
                    playing={true}
                    download={true}
                  />
                    :
                    <ReactAudioPlayer
                      autoPlay={false}
                      audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/TotalRevitalizationChambers-Zoom-Session-1-vip.mp3"
                      onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: Total Revitalization Chambers Session VIP 1')}
                      title="Total Revitalization Chambers Session VIP 1"
                    />
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/TotalRevitalizationChambers-Zoom-Session-1-vip.mp3" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 1')}>
                        <Icon name="download2" />
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }
                </StyledVideoTestimonialVideo>
                <div style={{ 'text-align': 'center', 'background': '#f9f9f9', 'padding': '2rem' }}>
                  <h2 style={{ 'text-align': 'center', 'marginTop': '2.4rem', 'marginBottom': '1.6rem' }}>3 Suns of Renewal Meditation</h2>
                  <p style={{ 'text-align': 'center' }}>This meditation has been cut from Kaye's Radiant Essence Renewal call on March 14th.</p>
                  <StyledVideoTestimonialVideo className="replay">
                    {isWatchFullVersionOpenMeditations ? <ReactVideoPlayer
                      videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/TotalRevitalizationChambers-Zoom-Session-1-vip-3-suns-or-renewal.mp4"
                      title="Total Revitalization Chambers Session 1 VIP - 3 suns of renewal meditation"
                      onPlay={e => analyticClickEvent('Click', 'Watch', 'Play: Total Revitalization Chambers Session 1 VIP - 3 suns of renewal meditation')}
                      playing={true}
                      download={true}
                    />
                      :
                      <>
                        <ReactAudioPlayer
                          autoPlay={false}
                          audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/TotalRevitalizationChambers-Zoom-Session-1-vip-3-suns-or-renewal.mp3"
                          onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: Total Revitalization Chambers Session 1 VIP - 3 suns of renewal meditation')}
                          title="Total Revitalization Chambers Session 1 VIP - 3 suns of renewal meditation"
                        />

                      </>
                    }

                    {!isWatchFullVersionOpenMeditations ?
                      <>
                        <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/TotalRevitalizationChambers-Zoom-Session-1-vip-3-suns-or-renewal.mp3" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 2 - 3 Suns of Renewal audio')}>
                          <Icon name="download2" />
                          Download audio</StyledLinkButton>

                        <StyledLinkButton className="download-pdf" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+Three+Suns+of+Renewal.pdf" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 2 - 3 Suns of Renewal PDF')}>
                          <Icon name="pdf" />
                          Download PDF</StyledLinkButton>
                        <StyledButton className="watchlisten-more" onClick={() => toggleTestimonialsMeditation(true)}>Watch the replay</StyledButton>
                      </>
                      :
                      <>
                        <StyledLinkButton className="download-pdf" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+Three+Suns+of+Renewal.pdf" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 2 - 3 Suns of Renewal PDF')}>
                          <Icon name="pdf" />
                          Download PDF</StyledLinkButton>
                        <StyledButton className="watchlisten-more" onClick={() => toggleTestimonialsMeditation(false)}>Listen to the replay</StyledButton>

                      </>
                    }
                  </StyledVideoTestimonialVideo>
                </div>
              </TabPanel>

              <TabPanel>
                {/* Session 2 */}
                <h2 style={{ 'text-align': 'center' }}>Radiant Essence Renewal - Call 2</h2>
                <p style={{ 'text-align': 'center' }}>Session 1 on Mar 29, 2025</p>
                <StyledVideoTestimonialVideo className="replay">
                  {isWatchFullVersionOpen ? <ReactVideoPlayer
                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/TotalRevitalizationChambers-Zoom-Session-2-vip.mp4"
                    title="Total Revitalization Chambers Session VIP 2"
                    playing={true}
                    download={true}
                  />
                    :
                    <ReactAudioPlayer
                      autoPlay={false}
                      audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/TotalRevitalizationChambers-Zoom-Session-2-vip.mp3"
                      onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: Total Revitalization Chambers Session VIP 2')}
                      title="Total Revitalization Chambers Session VIP 2"
                    />
                  }

                  {!isWatchFullVersionOpen ?
                    <>
                      <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/TotalRevitalizationChambers-Zoom-Session-2-vip.mp3" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 1')}>
                        <Icon name="download2" />
                        Download audio</StyledLinkButton>
                      <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(true)}>Watch the replay</StyledButton>
                    </>
                    :
                    <StyledButton className="watchlisten-more" onClick={() => toggleTestimonials(false)}>Listen to the replay</StyledButton>
                  }
                </StyledVideoTestimonialVideo>


                <div style={{ 'text-align': 'center', 'background': '#f9f9f9', 'padding': '2rem' }}>

                  <p style={{ 'text-align': 'center' }}>The two meditations below have been cut from Kaye's second call on March 29th.</p>
                  <h2 style={{ 'text-align': 'center', 'marginTop': '2.4rem', 'marginBottom': '1.6rem' }}>The 7 Suns of Vital Radiance</h2>

                  <StyledVideoTestimonialVideo className="replay">
                    {isWatchFullVersionOpenMeditations1 ? <ReactVideoPlayer
                      videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+7+Suns+of+Vital+Radiance.mp4"
                      title="Total Revitalization Chambers Session 2 VIP - 7 Suns of Vital Radiance"
                      onPlay={e => analyticClickEvent('Click', 'Watch', 'Play: Total Revitalization Chambers Session 2 VIP - 7 Suns of Vital Radiance')}
                      playing={true}
                      download={true}
                    />
                      :
                      <>
                        <ReactAudioPlayer
                          autoPlay={false}
                          audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+7+Suns+of+Vital+Radiance.mp3"
                          onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: Total Revitalization Chambers Session 2 VIP - 7 Suns of Vital Radiance')}
                          title="Total Revitalization Chambers Session 2 VIP - 7 Suns of Vital Radiance"
                        />

                      </>
                    }

                    {!isWatchFullVersionOpenMeditations1 ?
                      <>
                        <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+7+Suns+of+Vital+Radiance.mp3" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 2')}>
                          <Icon name="download2" />
                          Download audio</StyledLinkButton>
                        <StyledLinkButton className="download-pdf" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+7+Suns+of+Vital+Radiance.pdf" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 2 - 7 Suns of Vital Radiance PDF')}>
                          <Icon name="pdf" />
                          Download PDF</StyledLinkButton>
                        <StyledButton className="watchlisten-more" onClick={() => toggleTestimonialsMeditation1(true)}>Watch the replay</StyledButton>
                      </>
                      :
                      <>
                        <StyledLinkButton className="download-pdf" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+7+Suns+of+Vital+Radiance.pdf" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 2 - 7 Suns of Vital Radiance PDF')}>
                          <Icon name="pdf" />
                          Download PDF</StyledLinkButton>
                        <StyledButton className="watchlisten-more" onClick={() => toggleTestimonialsMeditation1(false)}>Listen to the replay</StyledButton>
                      </>
                    }
                  </StyledVideoTestimonialVideo>

                  <h2 style={{ 'text-align': 'center', 'marginTop': '4.4rem', 'marginBottom': '1.6rem' }}>The 5 Suns of Elemental Harmony</h2>
                  <StyledVideoTestimonialVideo className="">
                    {isWatchFullVersionOpenMeditations ? <ReactVideoPlayer
                      videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+5+Suns+of+Elemental+Harmony.mp4"
                      title="Total Revitalization Chambers Session 2 VIP - The 5 Suns of Elemental Harmony"
                      onPlay={e => analyticClickEvent('Click', 'Watch', 'Play: Total Revitalization Chambers Session 2 VIP - The 5 Suns of Elemental Harmony')}
                      playing={true}
                      download={true}
                    />
                      :
                      <>
                        <ReactAudioPlayer
                          autoPlay={false}
                          audioURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+5+Suns+of+Elemental+Harmony.mp3"
                          onPlay={e => analyticClickEvent('Click', 'Audio', 'Play: Total Revitalization Chambers Session 2 VIP -The 5 Suns of Elemental Harmony')}
                          title="Total Revitalization Chambers Session 2 VIP - The 5 Suns of Elemental Harmony"
                        />

                      </>
                    }

                    {!isWatchFullVersionOpenMeditations ?
                      <>
                        <StyledLinkButton className="download-audio" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+5+Suns+of+Elemental+Harmony.mp3" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 2 - The 5 Suns of Elemental Harmony Audio')}>
                          <Icon name="download2" />
                          Download audio</StyledLinkButton>
                        <StyledLinkButton className="download-pdf" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+5+Suns+of+Elemental+Harmony.pdf" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 2 - The 5 Suns of Elemental Harmony PDF')}>
                          <Icon name="pdf" />
                          Download PDF</StyledLinkButton>
                        <StyledButton className="watchlisten-more" onClick={() => toggleTestimonialsMeditation(true)}>Watch the replay</StyledButton>
                      </>
                      :
                      <>
                        <StyledLinkButton className="download-pdf" inverse={true} href="https://karinagrant.s3.eu-west-1.amazonaws.com/program/fhtj-total-revitalization-chambers/The+5+Suns+of+Elemental+Harmony.pdf" download onClick={() => analyticClickEvent('click', "Download", 'Total Revitalization Chambers Session VIP 2 - The 5 Suns of Elemental Harmony PDF')}>
                          <Icon name="pdf" />
                          Download PDF</StyledLinkButton>
                        <StyledButton className="watchlisten-more" onClick={() => toggleTestimonialsMeditation(false)}>Listen to the replay</StyledButton>
                      </>
                    }
                  </StyledVideoTestimonialVideo>
                </div>
              </TabPanel>
            </Tabs>
          </EventListSection>
        </StyledVideoTestimonialArticle>



        <Section>
          <Heading level={1} className="hidden"> Audio Programs &amp; Meditations</Heading>
          <ProductList posts={products} />
        </Section>

      </LayoutNoStripe>
    </>
  )
}

export default FhtjTotalRevitalizationChambersRecordingsVIP

export const pageQuery = graphql`
      query {
        site {
        siteMetadata {
        title
      }
    }

      wpPage(slug: {eq: "homepage" }) {
        title
      slug
      content
      testimonials {
        blockquote {
        citeLocation
          citationName
      quote
        }
      testimonialHeading
      testimonialLede
      }
    }

      aboutKarina: wpPage(slug: {eq: "about" }) {
        aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
        localFile {
        childImageSharp {
        gatsbyImageData
      }
          }
        }
      }
    }
      

      allWpPost(sort: {fields: [date], order: DESC } limit: 5) {
        nodes {
        excerpt
        uri
      date(formatString: "MMMM DD, YYYY")
      title
      isSticky
      categories {
        nodes {
        slug
      }
        }
      featuredImage {
        node {
        altText
            localFile {
        childImageSharp {
        gatsbyImageData(
          width: 975
      quality: 100
      placeholder: TRACED_SVG
      breakpoints: [320, 720, 1024, 1600]
      )
              }
            }
          }
        }
      }
    }

    allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    #   allWpProduct(limit: 3) {
    #     edges {
    #     node {
    #     title
    #       uri
    #   excerpt
    #   productsTag {
    #     nodes {
    #     name
    #   }
    #       }
    #   productData {
    #     pricefull
    #         pricesale
    #   stripefullprice
    #   stripesaleprice
    #   hidesale
    #       }
    #   featuredImage {
    #     node {
    #     localFile {
    #     childImageSharp {
    #     gatsbyImageData
    #   }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
}
      `
